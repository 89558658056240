@import '_base';

.showPicker {
  width: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  background: $color-background;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transition: top 250ms ease-in-out;
  top: -100vh;
  z-index: 3;

  &.st-active {
    top: 0;
  }

  &_list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: center;
    margin-top: 30px;

    button {
      margin: 5px;
    }

    .forgetMe {
      margin: 0;
      position: absolute;
      bottom: 10px;
      left: 10px;
      font-size: 12px;
      background: white;
      color: black;
      text-transform: black;
    }
  }
}

.showPicker_panel {
  max-width: $content-width;
  padding: 0 20px;
  margin: 0 auto;

  @media only screen and (max-height: $miniPhoneHeight) {
    padding: 0 5px;
  }
}

.react-autosuggest {
  &__container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  &__input {
    -webkit-appearance: none;
    width: 100%;
    height: 50px;
    border-color: transparent;
    border-radius: 0;
    min-height: 50px;
    border-width: 1px;
    border-style: solid;
    padding: 0 20px;
    font-size: 20px;
  }

  &__suggestions {
    &-container--open {
      background: white;
      border: 1px solid hsl(0, 0%, 80%);
      width: 100%;
      position: absolute;
      z-index: 5;
    }

    &-list {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  &__suggestion {
    padding: 15px 20px;
    cursor: pointer;

    &--highlighted {
      background: $color-accent-faded;
    }
  }
}
