@import '_base';

*,
*::before,
*::after {
  box-sizing: border-box;
}

@font-face {
  font-family: 'IBM Plex Mono';
  src:
    url('../fonts/IBMPlexMono-Regular-Latin1.woff') format('woff'),
    url('../fonts/IBMPlexMono-Regular-Latin1.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans Bold';
  src:
    url('../fonts/IBMPlexSans-Bold-Latin1.woff') format('woff'),
    url('../fonts/IBMPlexSans-Bold-Latin1.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family:
    'IBM Plex Mono',
 'Menlo',
 'DejaVu Sans Mono',
    'Bitstream Vera Sans Mono',
 Courier,
 monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'IBM Plex Sans Bold', 'Helvetica Neue', Arial, sans-serif;
  font-weight: normal;
}

h1 {
  color: $color-accent;
  font-size: 60px;
  line-height: 0.9em;
  letter-spacing: 0.3px;
  margin: 20px 0;
  text-align: center;

  @media only screen and (max-height: $miniPhoneHeight) {
    font-size: 40px;
  }
}

h2 {
  text-align: center;
}

h3 {
  text-align: center;
}

input,
textarea,
select,
button {
  font-family:
    'IBM Plex Mono',
 'Menlo',
 'DejaVu Sans Mono',
    'Bitstream Vera Sans Mono',
 Courier,
 monospace;
  font-weight: normal;
}

p {
  text-align: left;
}

abbr {
  text-decoration: none;
}

button {
  background: $color-accent;
  color: white;
  padding: 10px;
  transition: background 300ms ease-in-out;
  cursor: pointer;
  border: 0;
  font-weight: normal;
  font-size: 14px;
  border-radius: 5px;
}

button:hover {
  background: #ea529e;
}

button:disabled {
  opacity: 0.3;
}

.toolbar {
  $button-spacing: 5px;

  display: flex;
  margin: 10px -$button-spacing 0 -$button-spacing;

  button {
    margin: 0 $button-spacing;
    flex-grow: 1;
  }
}

.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.results {
  max-width: $content-width;
  min-height:
    calc(
      100vh - #{$footer-height}
    ); /* Fallback for browsers that do not support Custom Properties */

  min-height: calc((var(--vh, 1vh) * 100) - #{$footer-height});
  padding: 20px;
  margin: 0 auto;

  &:focus {
    outline: none;
  }

  h1 {
    font-size: 50px;
    margin-top: 50px;
  }
}

.credit {
  height: $footer-height;
  line-height: $footer-height;
  color: $color-accent;
  padding: 0 20px;
  text-align: right;

  a {
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
