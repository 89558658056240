$pink: hotpink;
$lightgrey: #dbdbdb;

$color-accent: coral;
$color-accent-faded: #fbc1a3;
$color-background: antiquewhite;

$footer-height: 70px;
$content-width: 500px;

$miniPhoneHeight: 568px;
